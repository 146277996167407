const getListSEOContent = (
  slug: string,
  page = 0,
  jobCount = 0,
  location?: boolean
) => {
  const today = new Date();
  const month = today.toLocaleString('default', {month: 'long'});
  const year = today.getUTCFullYear();
  return {
    title: `${page > 1 ? `Page-${page} for ` : ''}${slug} ${
      slug?.includes('Jobs') ? '' : 'Jobs'
    }, ${
      jobCount > 0 ? jobCount : ''
    } Job Vacancies for ${slug} in ${month} ${year}`,
    description: `Apply to ${jobCount > 0 ? jobCount : ''} ${slug} ${
      slug?.includes('Jobs') ? '' : 'Jobs'
    } on ${process.env.NEXT_PUBLIC_APP_NAME}.com. ${
      location
        ? `India's No.1 Management Job Portal. Explore ${slug} Now!`
        : 'Find high paying premium'
    }`,
  };
};
const getDetailSEOContent = (jobDetails: {
  jobTitle: string;
  companyName: string;
  location: string;
  exp: string;
}) => {
  return {
    title: jobDetails?.jobTitle,
    description: `Job Description for ${jobDetails?.jobTitle} in ${jobDetails?.companyName} in ${jobDetails?.location} for ${jobDetails?.exp}`,
  };
};

const getPageUrl = (slugUrl: string, pageNo: number): string => {
  return pageNo > 1
    ? `${slugUrl?.split('?')[0]}?page=${pageNo}`
    : slugUrl?.split('?')[0];
};

const getSyncPageTitle = (title: string, pageNo: number): string => {
  const regex = /Page-(\d+)/;

  if (regex.test(title)) {
    if (pageNo <= 1) {
      return title.split('for ')[1];
    }
    return title.replace(regex, `Page-${pageNo}`);
  } else if (pageNo > 1) {
    return `Page-${pageNo} for ${title}`;
  }

  return title;
};

const getSyncPageDescription = (
  description: string,
  pageNo: number
): string => {
  const regex = /Page-(\d+)/;

  if (regex.test(description)) {
    if (pageNo <= 1) {
      return description.split('Page-')[0];
    }
    return description.replace(regex, `Page-${pageNo}`);
  } else if (pageNo > 1) {
    return `${description} Page-${pageNo}`;
  }

  return description;
};

const getMeta = (metaKey: string) => {
  const metas = document.getElementsByTagName('meta');

  for (let i = 0; i < metas.length; i++) {
    if (metas[i].getAttribute('name') === metaKey) {
      return metas[i].getAttribute('content');
    }
  }

  return '';
};

export {
  getListSEOContent,
  getDetailSEOContent,
  getPageUrl,
  getSyncPageTitle,
  getMeta,
  getSyncPageDescription,
};
