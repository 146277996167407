import dynamic from 'next/dynamic';
import {ROUTE_SEARCH} from '@constants';
import {openUrl} from '@jobseeker/common-core/lib/utils/common';

const DynamicSearchOverlay = dynamic(
  () => import('@jobseeker/client-harness/lib/components/SearchHarness')
);

const InjectSearchOverlay = ({isSearchEnabled}: {isSearchEnabled: boolean}) => {
  const handleSearchSubmit = (
    keyword: string,
    location: string,
    experience: {
      minexp: number;
      maxexp: number;
    },
    posting?: string[]
  ) => {
    console.log(location, experience, posting, keyword, 'something');
    const queryString = new URLSearchParams({
      loc: location,
      minexp: experience?.minexp?.toString() ?? '',
      maxexp: experience?.maxexp?.toString() ?? '',
      posting: posting?.length ? posting?.join() ?? '' : '',
    }).toString();
    const url = `${ROUTE_SEARCH}/${keyword}-jobs?${queryString}`;

    openUrl(url, '_self');
  };

  return isSearchEnabled ? (
    <DynamicSearchOverlay
      isSearchEnabled={isSearchEnabled}
      handleSearchSubmit={handleSearchSubmit}
    />
  ) : (
    <></>
  );
};

export default InjectSearchOverlay;
